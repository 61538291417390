<template>
  <div>
    <c-search-box @enter="search">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" 
            name="plantCd" 
            :changeItem="changeItem" 
            v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="2M"
            type="month"
            label="월(개선 - 요청일 기준 / 즉시조치 - 조치일 기준)"
            name="actionCompleteRequestDates"
            v-model="searchParam.actionCompleteRequestDates"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 요청업체 -->
          <c-vendor
            :plantCd="searchParam.plantCd"
            :changeItem="changeItem"
            label="요청업체"
            name="improveRequestDeptCd"
            v-model="searchParam.improveRequestDeptCd" />
        </div>
      </template>
    </c-search-box>
    <c-tab
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :searchParam.sync="searchParam"
          :tabParam.sync="tabParam"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'impr-action-rate',
  data() {
    return {
      searchParam: {
        plantCd: null,
        actionCompleteRequestDates: [],
        improveRequestDeptCd: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      tab: 'task',
      tabItems: [
        { name: 'task', icon: 'task_alt', label: '업무별', component: () => import(`${'./imprTaskRate.vue'}`) },
        { name: 'rqstDept', icon: 'campaign', label: '조치업체별', component: () => import(`${'./imprRqstDeptRate.vue'}`) },
      ],
      tabParam: {
        search: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    search() {

    },
  }
};
</script>
